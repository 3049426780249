import { Decorate } from './types';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { H5ServiceAuth } from '@/api/common';

/*
 * Decorate H5Service
 */
export default class H5Service extends Decorate {
  async _addToken(config: AxiosRequestConfig) {
    const apiKey = await H5ServiceAuth.getApiKey();
    config.headers['Authorization'] = `Bearer ${apiKey}`;
    return config;
  }

  async config(config: AxiosRequestConfig) {
    await super.config(config);
    if (!config.url.endsWith('api/auth/api-key')) {
      await this._addToken(config);
    }
  }
}
