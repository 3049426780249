import Config from '@/config';

export const PROXY_URL_INCLUDE = [
  Config.SSO.URL,
  Config.SSO.URL_CN,
  Config.ZODIAC.SERVICE_ENDPOINT,
  Config.HOTEL.URL,
];

export const PROXY_URL_EXCLUDE = [];
