import http from '../base/http';
import Config from '@/config';

let remoteConfig: any = {};

const getConfig = async <T>() => {
  return http.get<T>(
    `${Config.AIRAISA_STATIC}/wmp/miniapp/config/config.json?t=${Date.now()}`,
  );
};

export async function getRemoteConfig(key) {
  if (!remoteConfig[key]) {
    remoteConfig = await getConfig();
  }
  return remoteConfig[key];
}
