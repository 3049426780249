import http from '../../base/http';
import Config from '@/config';
import { getRemoteConfig } from '@/api/remote-config';

interface RefreshTokenResponse {
  accessToken: string;
}

export const refreshToken = async (refreshToken: string) => {
  const ssorEndpoint = await getRemoteConfig('ssorEndpoint');
  const res = await http.post<RefreshTokenResponse>(
    `${ssorEndpoint}/auth/v2/authorization/by-refresh-token?clientId=${Config.SSO.CLIENT}`,
    { refreshToken },
    {
      headers: {
        origin: `${Config.SSO.ORIGIN}`,
      },
    },
  );

  return res.accessToken;
};

export const getUserInfo = async (params) => {
  const ssorEndpoint = await getRemoteConfig('ssorEndpoint');
  const { userId, accessToken } = params;
  const res = await http.post<User>(
    `${ssorEndpoint}/um/v2/users/${userId}`,
    {},
    {
      headers: {
        origin: `${Config.SSO.ORIGIN}`,
        Authorization: accessToken,
        'x-aa-client-id': `${Config.SSO.CLIENT}`,
      },
    },
  );

  return res;
};

interface TokenResponse {
  apiKey: string;
}
export const getApiKey = async () => {
  const ssorEndpoint = await getRemoteConfig('ssorEndpoint');
  const result: TokenResponse = await http.get<TokenResponse>(
    `${ssorEndpoint}/config/v2/clients/by-origin`,
    {},
    {
      headers: {
        origin: `${Config.SSO.ORIGIN}`,
        'x-aa-client-id': `${Config.SSO.CLIENT}`,
      },
    },
  );
  return result.apiKey;
};
