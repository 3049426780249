import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from '@/config';
import { PROXY_URL_INCLUDE, PROXY_URL_EXCLUDE } from '@/helper/constants';

class Proxy {
  private isProxied: boolean;

  constructor(url: string) {
    this.isProxied =
      PROXY_URL_INCLUDE.some((item) => url.startsWith(item)) &&
      PROXY_URL_EXCLUDE.every((item) => !url.startsWith(item));
  }

  config(config: AxiosRequestConfig): AxiosRequestConfig {
    if (!this.isProxied) return config;

    const data = config.data;
    config.data = {
      url: config.url,
      headers: config.headers,
      data,
    };
    config.headers = {};

    const urlObj = new URL(config.url);
    config.url = `${
      Config.H5_SERVICE.URL
    }/api/proxy/${config.method.toLowerCase()}?u=${
      urlObj.pathname + urlObj.search
    }`;
    config.method = 'post';

    return config;
  }

  success(response: AxiosResponse): AxiosResponse {
    if (!this.isProxied) return response;

    response = { ...response, ...response.data.data };
    return response;
  }

  error(error: AxiosError): AxiosError {
    if (!this.isProxied) return error;

    const response = error?.response;
    const responseData = response?.data as any;

    if (responseData?.code == '20005') {
      const factResponse = responseData?.error?.response;
      error.response = {
        ...error.response,
        ...factResponse,
      };
    }

    return error;
  }
}
export default Proxy;
