import FEATURE_SWITCH from './feature';

const env = process.env;

const SSO = {
  URL: env.REACT_APP_SSO_URL,
  URL_CN: env.REACT_APP_SSO_URL_CN,
  CLIENT: env.REACT_APP_SSO_CLIENT_ID,
  ORIGIN: env.REACT_APP_SSO_ORIGIN,
  REFRESH_TOKEN_DURATION: env.REACT_APP_SSO_REFRESH_TOKEN_DURATION,
};

const ZODIAC = {
  ENDPOINT: env.REACT_APP_ZODIAC_ENDPOINT,
  SERVICE_ENDPOINT: env.REACT_APP_ZODIAC_SERVICE_ENDPOINT,
  CHANNEL_HASH: env.REACT_APP_ZODIAC_CHANNEL_HASH,
};

const H5_SERVICE = {
  URL: env.REACT_APP_H5_SERVICE_URL,
};

const HOTEL = {
  URL: env.REACT_APP_HOTELSBE_URL,
  CLIENT_ID: env.REACT_APP_HOTELSBE_CLIENT_ID,
  GAID: env.REACT_APP_HOTELSBE_GAID,
  DELAYHOURS: parseInt(env.REACT_APP_HOTELSBE_DELAYHOURS),
};

const WMP = {
  BUSINESS_URL: env.REACT_APP_WMP_BUSINESS_URL,
};

export default {
  FEATURE_SWITCH,
  APP_ENV: env.REACT_APP_ENV,
  BASE_URL: env.REACT_APP_BASEURL,
  CDN_URL: env.REACT_APP_CDN_URL,
  APP_AIRAISA_URL: env.REACT_APP_AIRAISA_URL,
  AIRAISA_STATIC: env.REACT_APP_AIRASIA_STATIC,
  AIRASIA_WMP_H5: env.REACT_APP_AIRASIA_WMP_H5,
  MAX_RE_REQUEST_COUNT: parseInt(env.REACT_APP_MAX_RE_REQUEST_COUNT),
  HOTEL,
  SSO,
  H5_SERVICE,
  ZODIAC,
  WMP,
};
