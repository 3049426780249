export default {
  notInWhiteLit: 'You are not in the whitelist and have no right to use.',
  unknownError: '发生未知错误，请重新尝试',
  aaCheckoutErrors: {
    _default: '初始化订单失败',
    _title: '提交失败',
    _buttonTitle: '确认',
    flightUnavailable:
      '您选择的航班价格已发生变动，请重新搜索获取更新、更优惠价格的航班',
    sessionUnavailable: '您的操作已超时，请重新搜索',
    errorDownStreamServiceCallFailed:
      '您所选择的航班订购失败，请重新搜索以获取更新、更优惠价格的航班',
    notSupported:
      '您所选择的航班订购失败，请重新搜索以获取更新、更优惠价格的航班',
    errorInvalidPassengerDOB:
      '请查检您是否填写了正确的乘客出生日期，请修改后再尝试',
    seatUnavailable: '您所选择的坐位不可用，请修改后再尝试',
    errorDuplicatePassportNumber: '您使用了重复的护照号码，请修改后再尝试',
    baggageUnavailable: '您所选择的行李业务不可用，请修改后再尝试',
    // errorPerformingCheckout:
    //   '请查检您是否填写了正确的旅行文件(身份证或护照信息)，请修改后再尝试',
    errorPerformingCheckout: '初始化订单失败',
    invalidNationality: '您的“国籍/地区”选择有误，请确认并修改后再尝试提交',
  },
};
