import i18n from '@/helper/i18n';
import { makeObservable, observable, action } from 'mobx';
import { groupByKey } from '@/helper/util';
import { Log } from '@/helper/log';
import { transform } from './parse';

const t = i18n.chain;
const METHOD_NAMES = {
  UPDATE_DATA: 'updateData',
} as const;

export default class FareDetailsStore {
  private CLASS_NAME = 'FareDetailsStore';
  @observable transactionCurrency = '';
  @observable priceChanged = false;

  @observable conversionRates = null;
  @observable transformedFareDetails: AACheckout.FareDetailItem[] = [];
  @observable plainAddonItems: AACheckout.PlainAddonItem[] = [];
  @observable addonTotalPrices: number[] = [];
  @observable journeys: AACheckout.Journey[] = [];

  @observable baseFare = 0;
  @observable tax = 0;

  baseCurrency = '';
  originalTotalAmount = 0;

  constructor() {
    makeObservable(this);
  }

  get totalAmount() {
    return [this.originalTotalAmount, ...this.addonTotalPrices].reduce(
      (a, b) => a + b,
      0,
    );
  }

  get fareDetails(): AACheckout.FareDetailItem[] {
    const plainAddonItems = this.plainAddonItems;
    const journeys = this.journeys;

    // group addon items
    const fareBreakdown: AACheckout.FareBreakdownItem[] = [];

    fareBreakdown.push(
      ...this.generateAddonBreakdown(plainAddonItems, journeys, false),
    );

    fareBreakdown.push(...this.generateInsuranceBreakdown(plainAddonItems));

    fareBreakdown.push(
      ...this.generateAddonBreakdown(plainAddonItems, journeys, true),
    );

    return [
      ...this.transformedFareDetails,
      {
        fareType: 'addOns',
        currency: this.transactionCurrency,
        isDiscount: false,
        title: t.flights.checkout.fareSummary.addOns,
        totalAmount: this.addonTotalPrices.reduce((a, b) => a + b, 0),
        fareBreakdown,
      },
    ];
  }

  generateAddonBreakdown(
    plainAddonItems: AACheckout.PlainAddonItem[],
    journeys: AACheckout.Journey[],
    isWheelchair: boolean,
  ) {
    const fareBreakdown: AACheckout.FareBreakdownItem[] = [];
    const types = isWheelchair
      ? ['wheelchair']
      : ['bundle', 'fastPass', 'baggage', 'seat', 'meal'];

    if (isWheelchair) {
      fareBreakdown.push({
        currency: '',
        fare_title: t.flights.aaCheckout.ancillary.wheelchairSelector.title,
        type: 'header',
      });
    }

    for (const journey of journeys) {
      const allRoutes = [
        journey.origin.stationCode + journey.destination.stationCode,
      ].concat(
        journey.segments.map((s) => s.departureStation + s.arrivalStation),
      );

      const filteredItems: AACheckout.PlainAddonItem[] = plainAddonItems.filter(
        (a) =>
          allRoutes.includes(a.departureStation + a.arrivalStation) &&
          (types as string[]).includes(a.type),
      );

      if ((isWheelchair && filteredItems.length > 0) || !isWheelchair) {
        fareBreakdown.push({
          currency: '',
          fare_title: `${journey.origin.stationName} - ${journey.destination.stationName}`,
          type: 'header',
        });
      }

      fareBreakdown.push(...this.groupBy(filteredItems));
    }

    return fareBreakdown;
  }

  generateInsuranceBreakdown(plainAddonItems: AACheckout.PlainAddonItem[]) {
    const fareBreakdown: AACheckout.FareBreakdownItem[] = [];
    const insuranceItems: AACheckout.PlainAddonItem[] = plainAddonItems.filter(
      (a) => a.type === 'insurance',
    );

    if (insuranceItems.length > 0) {
      fareBreakdown.push({
        currency: '',
        fare_title: t.flights.aaCheckout.ancillary.insurance.headerTitle,
        type: 'header',
      });

      fareBreakdown.push(...this.groupBy(insuranceItems));
    }

    return fareBreakdown;
  }

  generateTitle(firstItem: any, items: any[]): string {
    let title: string;

    switch (firstItem.type) {
      case 'seat':
        title = `${items.length} x ${firstItem.name}(${items
          .map((i) => i.properties.seatNumber)
          .join(',')})`;
        break;

      case 'fastPass':
      case 'bundle':
        title = `${firstItem.name} 适用${items.length}名乘客`;
        break;

      case 'insurance':
        title = firstItem.name;
        break;

      default:
        title = `${items.length} x ${firstItem.name}`;
        break;
    }

    return title;
  }

  groupBy(addonItems: AACheckout.PlainAddonItem[]) {
    const grouped = groupByKey(addonItems, 'groupBy');
    const fareBreakdown: any[] = [];
    for (const key in grouped) {
      const items = grouped[key];
      const firstItem = items[0];

      const price = items.map((i) => i.price).reduce((a, b) => a + b, 0);

      const title = this.generateTitle(firstItem, items);

      fareBreakdown.push({
        id: firstItem.id,
        currency: firstItem.currency,
        price,
        fare_title: title,
        type: firstItem.type === 'bundle' ? 'bundle_header' : firstItem.type,
      });

      if (firstItem.type === 'bundle') {
        //special handling for bundle
        const bundleSubItems = this.generateBundleSubItems(items);
        fareBreakdown.push(...bundleSubItems);
      }

      if (firstItem.type === 'fastPass') {
        //special handling for fastPass
        fareBreakdown.push({
          currency: '',
          fare_title: `${items.length} x Xtra carry-on 7kg`,
          type: 'bundle',
        });
      }
    }

    return fareBreakdown;
  }

  sortAndGroupSsrsByDisplayName(plainAddonItems: AACheckout.PlainAddonItem[]) {
    const order = ['baggage', 'seat', 'insurance', 'meals'];

    return plainAddonItems.reduce((acc, item) => {
      const sortedSsrs = item.properties.includedSsrs.slice().sort((a, b) => {
        const orderA = order.indexOf(a.ancillaryType);
        const orderB = order.indexOf(b.ancillaryType);

        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        }
        if (orderA !== -1) return -1;
        if (orderB !== -1) return 1;
        return 0;
      });

      sortedSsrs.forEach((ssr) => {
        if (ssr.ancillaryType !== 'other') {
          ssr.journeyDetails.forEach((jd) => {
            jd.selectedSsrs.forEach((selectedSsr) => {
              const ssrDisplayName = selectedSsr.name || ssr.ssrName;

              if (!acc[ssrDisplayName]) {
                acc[ssrDisplayName] = [];
              }
              acc[ssrDisplayName].push(ssr);
            });
          });

          // if selectedSsrs has not value, use ssrName
          if (ssr.journeyDetails.every((jd) => jd.selectedSsrs.length === 0)) {
            const ssrDisplayName = ssr.ssrName;

            if (!acc[ssrDisplayName]) {
              acc[ssrDisplayName] = [];
            }
            acc[ssrDisplayName].push(ssr);
          }
        }
      });

      return acc;
    }, {});
  }

  generateBundleSubItems(plainAddonItems: AACheckout.PlainAddonItem[]) {
    const bundleSubItems: any[] = [];
    const groupedBySsrName =
      this.sortAndGroupSsrsByDisplayName(plainAddonItems);

    for (const ssrDisplayName in groupedBySsrName) {
      const ssrItems = groupedBySsrName[ssrDisplayName];
      const length = ssrItems.length;
      bundleSubItems.push({
        currency: '',
        fare_title: `${length} x ${ssrDisplayName}`,
        type: 'bundle',
      });
    }

    return bundleSubItems;
  }

  @action
  updateData = async (originalPageData) => {
    try {
      const {
        baseCurrency,
        currency,
        totolAmount,
        priceChanged,
        fareDetails,
        conversionRates,
      } = await transform(originalPageData);
      const baseFareItem = fareDetails.find((f) => f.fareType === 'baseFare');
      const taxesItem = fareDetails.find((f) => f.fareType === 'taxes');

      baseFareItem && (this.baseFare = baseFareItem.totalAmount);
      taxesItem && (this.tax = taxesItem.totalAmount);

      this.baseCurrency = baseCurrency;
      this.transactionCurrency = currency;
      this.originalTotalAmount = totolAmount;
      this.priceChanged = priceChanged;
      this.transformedFareDetails = fareDetails;
      this.conversionRates = conversionRates;
    } catch (e) {
      Log.errorData(e, this.CLASS_NAME, METHOD_NAMES.UPDATE_DATA);
    }
  };

  composeCheckoutData() {
    return {
      totalCartOrder: this.totalAmount,
      totalCardOrderCurrency: this.transactionCurrency,
      currencyDetails: {
        baseCurrency: this.baseCurrency,
        transactionCurrency: this.transactionCurrency,
      },
    };
  }
}
